/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v26-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v26-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v26-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v26-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v26-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v26-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v26-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v26-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v26-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v26-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v26-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v26-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v26-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v26-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* source-code-pro-300 - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-code-pro-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-code-pro-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-code-pro-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-code-pro-v14-latin-300.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}

/* source-code-pro-regular - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-code-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-code-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-code-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-code-pro-v14-latin-regular.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}

/* source-code-pro-600 - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-code-pro-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-code-pro-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-code-pro-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-code-pro-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-code-pro-v14-latin-600.svg#SourceCodePro') format('svg'); /* Legacy iOS */
}

/* inconsolata-regular - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inconsolata-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inconsolata-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inconsolata-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inconsolata-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inconsolata-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inconsolata-v21-latin-regular.svg#Inconsolata') format('svg'); /* Legacy iOS */
}
